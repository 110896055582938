import React from "react";
import AppCorousel from "../../components/AppCorousel";
import { bannerImg } from "../../data/data";

const Banner = () => {
  return (
    <div id="home" className="h-screen overflow-hidden relative">
      <div className="pl-12 h-screen w-full bg-black/60 absolute z-40 flex flex-col justify-center">
        <h1 className="text-3xl md:text-5xl font-semibold text-white mb-3">SEM PROGRESSIVE</h1>
        <h1 className="text-3xl md:text-5xl font-semibold text-white">COMPANY LTD.</h1>
        <h1 className="text-3xl md:text-4xl text-green-600 font-bold tracking-widest">...</h1>
        <p className="md:text-lg text-sm font-normal mt-4 text-white">
          Sustainability Environment Management
        </p>
        <div className="h-[2px] w-56 md:w-[280px] bg-green-600 rounded mt-3" />
      </div>
      <AppCorousel className="h-screen" slides={bannerImg} />
    </div>
  );
};

export default Banner;
