import React from "react";
import { FaBullseye } from "react-icons/fa";

function Mission() {
  return (
    <div id="mission" className="p-5 m-5">
      <div className="flex items-center flex-col  border-b border-b-green-500 ">
        <div className="flex items-center justify-center text-gray-500 gap-3  mb-3 ">
          <div className="bg-green-100 p-3 rounded-full w-fit h-fit">
            <FaBullseye className="text-3xl text-green-600" />
          </div>
          <div>
            <h1 className="text-gray-700 text-2xl font-bold">
              Our Mission
            </h1>
          </div>
        </div>
        <div className="App-underline-heading"></div>
      </div>
      <div data-aos="fade-up" className="px-10 text-gray-500 pt-6 text-center">
        <p className="leading-7">
          To revolutionize agriculture in the southern Highlands of Tanzania by
          integrating sustainable poultry farming practices with cutting-edge
          renewable energy solutions, fostering economic growth, environmental
          sustainability, and community empowerment.
        </p>
      </div>
    </div>
  );
}

export default Mission;
