import React from "react";
import {
  FaPhoneAlt,
  FaInstagram,
  FaLinkedinIn,
  FaMailBulk,
} from "react-icons/fa";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { contacts } from "../../data/data";
import { navItems } from "../../data/data";
import { Link } from "react-scroll";
import greenImg from "../../images/greenland.png";

const Contact = () => {
  return (
    <div  id="contacts" className="m-5">
      <div className="flex items-center flex-col border-b border-b-green-500 ">
        <h1 className="text-2xl font-bold text-gray-700 mb-2 ">Contacts</h1>
        <div className="App-underline-heading"></div>
      </div>
      <div data-aos="fade-up" className="mt-11 flex w-full  gap-3 flex-wrap">
        <div className="bg-white shadow-sm rounded-lg p-7 flex-auto relative ">
          <div className="absolute -top-5 bg-green-100 border-4 border-gray-100 rounded-full w-fit h-fit p-3">
            <FaPhoneAlt className="text-xl text-green-600" />
          </div>
          <div>
            <h1 className="text-xl text-gray-700 font-bold my-2">
              Mbeya, Tanzania Branch
            </h1>
            <h2 className="mb-1">call us:</h2>
            {contacts &&
              contacts.mobile &&
              contacts.mobile.map((number, index) => (
                <div key={index} className="flex items-center">
                  <div className="bg-green-100 p-1 h-fit w-f mr-1 rounded-full">
                    <IoCheckmarkDoneCircleOutline className="text-green-600" />
                  </div>
                  <p className="text-gray-500">{number.phoneNo}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="bg-white shadow-sm rounded-lg p-7 flex-auto relative ">
          <div className="absolute -top-5 bg-green-100 border-4 border-gray-100 rounded-full w-fit h-fit p-3">
            <FaMailBulk className="text-xl text-green-600" />
          </div>
          <div>
            <h1 className="text-xl text-gray-700 font-bold my-2">Mail us</h1>
            <h2 className="mb-1">via g-mail:</h2>
            {contacts &&
              contacts.mail &&
              contacts.mail.map((address, index) => (
                <div key={index} className="flex items-center">
                  <div className="bg-green-100 p-1 h-fit w-f mr-1 rounded-full">
                    <IoCheckmarkDoneCircleOutline className="text-green-600" />
                  </div>
                  <p className="text-gray-500">{address.address}</p>
                </div>
              ))}
          </div>
        </div>
        <div className="bg-white shadow-sm rounded-lg p-7 flex-auto relative ">
          <div className="absolute -top-5 bg-green-100 border-4 border-gray-100 rounded-full w-fit h-fit p-3">
            <FaInstagram className="text-xl text-green-600" />
          </div>
          <div>
            <h1 className="text-xl text-gray-700 font-bold my-2">Follow us</h1>
            <h2 className="mb-1">on instagram:</h2>
            <div className="flex items-center">
              <div className="bg-green-100 p-1 h-fit w-f mr-1 rounded-full">
                <IoCheckmarkDoneCircleOutline className="text-green-600" />
              </div>
              {contacts && contacts.social && (
                <p className="text-gray-500">{contacts.social.instagram}</p>
              )}
            </div>
          </div>
        </div>
        <div className="bg-white shadow-sm rounded-lg p-7 flex-auto relative ">
          <div className="absolute -top-5 bg-green-100 border-4 border-gray-100 rounded-full w-fit h-fit p-3">
            <FaLinkedinIn className="text-xl text-green-600" />
          </div>
          <div>
            <h1 className="text-xl text-gray-700 font-bold my-2">
              Link with us
            </h1>
            <h2 className="mb-1">on Linkedin:</h2>
            <div className="flex items-center">
              <div className="bg-green-100 p-1 h-fit w-f mr-1 rounded-full">
                <IoCheckmarkDoneCircleOutline className="text-green-600" />
              </div>
              {contacts && contacts.social && (
                <p className="text-gray-500">{contacts.social.linkedIn}</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className="bg-white shadow-sm rounded-lg mt-4 flex md:flex-row flex-col justify-center gap-6">
        <div className="flex-1 relative ">
          <div className="absolute bg-green-900/70 h-64 w-full p-11 bg-blend-overlay">
            <h1 className="white text-3xl text-white font-bold">
              Sem Progressive Company Ltd.
            </h1>
            <h1 className="text-gray-100 mt-3">
              This is the era for revolution of agriculture, specifically in the
              poultry sector, and renewable energy solutions.
            </h1>
            <div className="flex gap-2 mt-3">
              <div className=" border border-white rounded-full w-8 h-8 text-center flex items-center justify-center">
                <a
                  className="text-white hover:text-green-400"
                  href="https://linkedin.com/company/sem-progressive-company/"
                >
                  <FaLinkedinIn />
                </a>
              </div>
              <div className=" border border-white rounded-full w-8 h-8 text-center flex items-center justify-center">
                <a
                  className="text-white hover:text-green-400"
                  href="https://www.instagram.com/semprogressivecompany/"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
          <img
            src={greenImg}
            alt={greenImg}
            className="w-full h-64 bg-blend-overlay"
          />
        </div>
        <div className="p-5 flex-3 ">
          <h1 className="text-gray-700 font-bold mb-4">Usefull Links.</h1>
          <ul className="text-gray-500 flex-2">
            {navItems &&
              navItems.map((link, index) => (
                <li key={index} className="cursor-pointer mt-1 hover:text-green-600">
                  <Link
                    to={link.id}
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                  >
                    {link.label}
                  </Link>
                </li>
              ))}
          </ul>
        </div>
        <div className=" text-gray-500 flex-2 p-4">
          <h1 className="text-gray-700 font-bold mb-5">Contact Us:</h1>
          <h1 className="font-semibold text-gray-700 mb-2">
            Hotline:{" "}
            <span className="font-normal text-gray-500">
              +255 754 816 342/ +255 7566 813 24/ +255 744 572 010
            </span>
          </h1>
          <h1 className="font-semibold text-gray-700">
            E-Mail:{" "}
            <span className="font-normal text-gray-500">
              <a className="hover:text-green-600" href="mailto:semprogressive@gmail.com">
                {" "}
                semprogressive@gmail.com
              </a>
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Contact;
