import React from 'react'
import Document from './Document'
import Service from './Service'

const AboutUs = () => {
  return (
    <div  id="aboutus" className="bg-gray-100 p-5 my-5 mx-1">
      <div className="flex items-center flex-col  border-b border-b-green-500 mb-2">
        <h1 className="text-2xl font-bold text-gray-700 mb-2">About Us</h1>
        <div className="App-underline-heading"></div>
      </div>
      <div  className=" flex md:flex-row flex-col gap-3 mt-7">
        <div data-aos="fade-up" className="bg-white p-10 shadow-sm rounded-md px-5 flex-1 text-gray-500">
          <h1 className="font-bold text-2xl text-gray-700">
            {" "}
            SEM Progressive Company Ltd is a pioneering enterprise based in the
            southern Highland region of Tanzania.
          </h1>
          <p className="leading-7">
            Our core focus areas include agriculture, specifically in the
            poultry sector, and renewable energy solutions. We are committed to
            integrating sustainable agricultural practices with innovative
            renewable energy technologies to enhance productivity and
            sustainability in the country.
          </p>
        </div>
        <div className="bg-white flex-2 shadow-sm p-10 rounded-lg  ">
          <Document />
        </div>
      </div>
      <Service />
    </div>
  )
}

export default AboutUs