import React from 'react'
import gr from "../../images/gr.jpg"
import { serviceItems } from '../../data/data'
import { projectItems } from '../../data/data'


const Statistics = () => {
  return (
    <div className='relative h-80'>
        <div className='absolute w-full h-80 bg-black/50'>
            <div className='flex flex-wrap items-center justify-center md:gap-6 gap-2 h-80'>
                <div data-aos="fade-up"  className='text-white text-center flex flex-col items-center gap-2 border md:p-6 p-2  border-white md:w-72 w-64'>
                    <h1 className='text-2xl font-semibold'>Services</h1>
                    <div className='h-[1.5px] bg-white w-20 rounded '></div>
                    <label className='text-4xl font-bold'>{serviceItems.length}</label>
                </div>
                <div data-aos="fade-up"  className='text-white text-center flex flex-col items-center gap-2 border md:p-6 p-2 border-white  md:w-72 w-64'>
                    <h1 className='text-2xl font-semibold'>Projects</h1>
                    <div className='h-[1.5px] bg-white w-20 rounded '></div>
                    <label className='text-4xl font-bold'>{projectItems.length}</label>
                </div>
            </div>
        </div>
        <img className='object-cover h-full w-full ' src={gr} alt="" />
    </div>
  )
}

export default Statistics