import React from "react";
import { BiCopyright } from "react-icons/bi";

const Footer = () => {
  return (
    <div className="bg-green-100 p-7 rounded-lg text-xs m-5">
      <div className="flex items-center justify-center gap-1 mb-2">
        <BiCopyright className=" text-gray-700" />
        <div>
          {" "}
          <p className="text-gray-800">Copyright All Rights Reserved.</p>
        </div>
      </div>
      <div>
        <p className="text-center  text-gray-800">
          designed by{" "}
          <span className="text-green-600">SEM Progressive Company Ltd.</span>
        </p>
      </div>
    </div>
  );
};

export default Footer;
