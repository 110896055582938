import React from "react";
import { FaCogs } from "react-icons/fa";
import { serviceItems, gallaries } from "../../data/data";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import AppCorousel from "../../components/AppCorousel";

const Service = () => {
  return (
    <div data-aos="fade-up" id="services" className="flex flex-col mt-7">
      <div className="w-full my-4 flex flex-auto items-center">
        <div className="h-[.7px] w-full bg-green-600 mr-2"></div>
        <div className="bg-green-600 px-8 py-2 rounded-full w-full">
          <h2 className="text-md text-center font-bold text-white">
            OUR SERVICES
          </h2>
        </div>
        <div className="h-[0.7px] w-full bg-green-600 ml-2" />
      </div>
      <div className="flex flex-col md:flex-row p-1 gap-3 ">
        <div data-aos="fade-up"  className="text-gray-500 p-5 my-2  bg-white shadow-sm rounded-lg">
          <div className="bg-green-100 p-5 rounded-full w-fit h-fit">
            <FaCogs className="text-3xl text-green-600" />
          </div>
          <div>
            <h1 className="text-gray-700 text-2xl font-bold mt-3">
              OUR SERVICES
            </h1>
          </div>
          {serviceItems &&
            serviceItems.map((service, index) => (
              <div key={index} className="flex  items-center mt-2 ">
                <div className="bg-green-100 p-1 h-fit w-f mr-6 rounded-full">
                  <IoCheckmarkDoneCircleOutline className="text-green-600" />
                </div>
                <p>{service.title}</p>
              </div>
            ))}
        </div>
        <div>
          <div data-aos="fade-up" className=" md:flex md:flex-wrap gap-4  items-center justify-center hidden">
            {gallaries &&
              gallaries.map((img, index) => (
                <div key={index} className="md:w-fit my-2 mr-2 shadow-sm md:shadow-sm w-full bg-white p-1 rounded-lg">
                  <img
                    src={img.imgPath}
                    className="md:h-72 rounded-lg"
                    alt={img.imgPath}
                  />
                </div>
              ))}
          </div>
          <div className="md:hidden bg-white shadow-sm p-1 rounded-lg">
            <AppCorousel slides={gallaries} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
