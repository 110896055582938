import { useEffect } from "react";
import "./App.css";
import Layout from "./layout/Layout";
import IndexPage from "./pages/IndexPage";
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({
      duration: 2000,
      offset: -100
     
    });
  }, []);
  return (
    <div className="bg-gray-100">
      <Layout>
        <IndexPage />
      </Layout>
    </div>
  );
}

export default App;
