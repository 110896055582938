import React from "react";
import { projectItems } from "../../data/data";
import { FaCogs } from "react-icons/fa";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import AppCorousel from "../../components/AppCorousel";

function Project() {
  return (
    <div data-aos="fade-up"  id="projects" className="p-1 m-5">
      <div className="flex items-center flex-col border-b border-b-green-500 ">
        <h1 className="text-2xl font-bold text-gray-700 mb-2 ">
          Our Current Projects
        </h1>
        <div className="App-underline-heading"></div>
      </div>
      <div className=" flex flex-col md:flex-row md:mt-5 mt-6 gap-3 ">
        <div className="text-gray-500 md:w-[50%] p-5 bg-white shadow-sm rounded-lg ">
          <div className="bg-green-100 p-5 rounded-full w-fit h-fit">
            <FaCogs className="text-3xl text-green-600" />
          </div>
          <div>
            <h1 className="text-gray-700 text-2xl font-bold mt-3">PROJECTS</h1>
          </div>
          {projectItems && (
            <div>
              {projectItems.map((project, index) => (
                <div key={index} className="flex items-center mt-2 ">
                  <div className="bg-green-100 p-1 h-fit w-f mr-6 rounded-full">
                    <IoCheckmarkDoneCircleOutline className="text-green-600" />
                  </div>
                  <p>{project.title}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="w-full pb-10 bg-white rounded-lg shadow-sm overflow-hidden">
          <AppCorousel slides={projectItems} />
        </div>
      </div>
    </div>
  );
}

export default Project;
