import React from "react";
import Header from "./components/Header";
import Banner from "./components/Banner";
import Footer from "./components/Footer";

const Layout = ({children}) => {
  return <div className="text-sm" >
    <Header />
    <Banner />
    <main>{children}</main>
    <Footer />
  </div>;
};

export default Layout;
