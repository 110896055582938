import React from "react";
import { FaRegLightbulb } from "react-icons/fa";

function Vision() {
  return (
    <div   id="vision" className="p-5 my-5 mx-5">
      <div className="flex items-center flex-col  border-b border-b-green-500">
        <div className="flex items-center justify-center text-gray-500 gap-3 mb-3">
          <div className="bg-green-100 p-3 rounded-full w-fit h-fit">
            <FaRegLightbulb className="text-3xl text-green-600" />
          </div>
          <div>
            <h1 className="text-gray-700 text-2xl font-bold">Our Vision</h1>
          </div>
        </div>
        <div className="App-underline-heading"></div>
      </div>
      <div data-aos="fade-up" className="px-10 text-gray-500 pt-6 text-center">
        <p className="leading-7">
          "To be a leading provider of sustainable poultry farming and renewable
          energy solutions in Tanzania, driving positive social and
          environmental impact while achieving excellence in agricultural
          innovation and productivity."{" "}
        </p>
      </div>
    </div>
  );
}

export default Vision;
