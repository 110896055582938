import img3 from "../images/img3.jpg";
import bloiler1 from "../images/bloiler1.jpg";
import hensImg from "../images/hens1.webp";
import solarimg from "../images/solar1.jpeg";
import csaImg from "../images/csa1.jpg";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";
import img7 from "../images/img7.png";
import img5 from "../images/img5.png";

export const navItems = [
  { label: "Home", id: "home" },
  { label: "About us", id: "aboutus" },
  { label: "Our Services", id: "services" },
  { label: "Our Mission", id: "mission" },
  { label: "Our Vission", id: "vision" },
  { label: "Our Current Projects", id: "projects" },
  { label: "Contact us", id: "contacts" },
];

export const bannerImg = [{ imgPath: img3 }, { imgPath: bloiler1 }];

export const projectItems = [
  {
    title: "Poultry production ",
    imgPath: hensImg,
    status: "carried",
  },

  {
    title: "Selling solar pannels to rural communities",
    imgPath: solarimg,
    status: "up coming",
  },

  {
    title: "Training to farmers on climate-smart Agriculture",
    imgPath: csaImg,
    status: "up coming",
  },
];

export const serviceItems = [
  {
    title:
      "Poultry Farming Services: Meat and Egg Production, Livestock Management.",
    description: "",
    imgPath: "",
  },
  {
    title:
      " Renewable Energy Solutions: Solar Power Systems, Biomass Energy, Energy Efficiency.",
    description: "",
    imgPath: "",
  },
  {
    title:
      "Consultancy and Training: Agricultural Consultancy, Training Programs.",
    description: "",
    imgPath: "",
  },
  {
    title: "Research and Development: Innovation Projects, Pilot Programs.",
    description: "",
    imgPath: "",
  },
];

export const documents = [
  {
    title: "Memorandum and Articles of Association.",
  },
  {
    title: "Certificates of Incoporation.",
  },
  {
    title: "Tax payer Identification Number(TIN).",
  },
];

export const contacts = {
  mobile: [
    { phoneNo: "+255 754 816 342" },
    { phoneNo: "+255 7566 813 24" },
    { phoneNo: "+255 744 572 010" },
  ],
  mail: [{ address: "semprogressive@gmail.com" }],
  social: {
    instagram: "semprogressivecompany",
    linkedIn: "Sem Progressive Company",
  },
};

export const gallaries = [
  { imgPath: img5 },
  { imgPath: img1 },
  { imgPath: img2 },
  { imgPath: img7 },
];
