import React, { useEffect, useState } from "react";
import { navItems } from "../../data/data";
import logo from "../../images/logo.JPG";
import { IoCloseOutline, IoMenuOutline } from "react-icons/io5";
import { Link, scrollSpy } from "react-scroll";

const Header = () => {
  const [isOpenNav, setIsOpenNav] = useState(false);
  const [active, setActive] = useState(0);

  useEffect(() => { scrollSpy.update()},[])

  const handleOnpenNav = () => setIsOpenNav(!isOpenNav);
  
  const handleOnActive = (index) => {
    setIsOpenNav(false);
    setActive(index);
  };

  const handleOnScrollSetActive = (to) => {
    setActive(to);
  };

  return (
    <div className="fixed w-full z-50 bg-neutral/80 backdrop-blur-lg">
      <header className="w-[92%] mx-auto pt-3">
        <nav className="bg-green-600 backdrop-blur-lg flex items-center justify-between p-2 rounded-md">
          <Link
            onClick={() => handleOnActive(0)}
            to={"home"}
            activeClass="active"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
            className="cursor-pointer"
          >
            <img src={logo} alt="no logo" width={40} />
          </Link>

          <ul className="text-white md:flex items-center gap-8 hidden md:mr-4">
            {navItems &&
              navItems.map((item, index) => (
                <li
                  className={`${
                    active === index && "after:scale-x-50"
                  } cursor-pointer relative w-fit block after:block after:content-[''] after:absolute after:h-[1px] after:mt-[3px] after:bg-white after:rounded-full after:w-full after:scale-x-0 after:hover:scale-x-50 after:transition after:duration-300 after:origin-center`}
                  key={index}
                >
                  <Link
                    to={item.id}
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={-100}
                    duration={500}
                    onSetActive={() => handleOnScrollSetActive(index)}
                  >
                    {item.label}
                  </Link>
                </li>
              ))}
          </ul>
          <button
            onClick={handleOnpenNav}
            className="text-white text-3xl md:hidden hover:bg-green-700 hover:rounded-full p-1"
          >
            {isOpenNav ? <IoCloseOutline /> : <IoMenuOutline />}
          </button>
        </nav>
        {isOpenNav && (
          <div className="bg-green-600 py-8 mt-[1px] md:hidden absolute rounded-lg w-[92%]">
            <ul className="flex flex-col gap-2 mx-6 text-white">
              {navItems &&
                navItems.map((item, index) => (
                  <li
                    className="cursor-pointer hover:bg-green-700 p-3 hover:rounded-lg transition-all ease-in-out duration-300"
                    key={index}
                  >
                    <Link
                      onClick={() => handleOnActive(index)}
                      to={item.id}
                      activeClass="active"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={500}
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        )}
      </header>
    </div>
  );
};

export default Header;
