import React from "react";
import { FaRegFileAlt } from "react-icons/fa";
import { documents } from "../../data/data";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";

function Document() {
  return (
    <div data-aos="fade-up"  id="document" className="flex flex-col">
      <div className="bg-green-100 p-5 rounded-full w-fit h-fit">
        <FaRegFileAlt className="text-3xl text-green-600" />
      </div>
      <div>
        <h1 className="text-gray-700 text-2xl font-bold mt-3">OUR DOCUMENTS</h1>
      </div>
      <div className="text-gray-500">
        {documents &&
          documents.map((document, index) => (
            <div key={index} className="flex items-center mt-2 ">
              <div className="bg-green-100 p-1 h-fit w-f mr-6 rounded-full">
                <IoCheckmarkDoneCircleOutline className="text-green-600" />
              </div>
              <p>{document.title}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Document;
