import React from "react";
import AboutUs from "./components/AboutUs";
import Mission from "./components/Mission";
import Vision from "./components/Vision";
import Project from "./components/Project";
import Statistics from "./components/Statistics";
import Contact from "./components/Contact";

const IndexPage = () => {
  return (
    <div>
      <AboutUs />
      <Statistics />
      <Mission />
      <Vision />
      <Project />
      <Contact />
    </div>
  );
};

export default IndexPage;
